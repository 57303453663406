.Toast {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  max-width: 100vw;
  background-color: #07c;
  color: #fff;
  text-align: center;
  z-index: 100;
  animation: slide-down 5s ease-out forwards;
}
.Toast.success {
  background-color: #5cb85c;
}
.Toast.warning {
  background-color: #cfd853;
  color: #424242;
}
.Toast.error {
  background-color: #d9534f;
}
.Toast.info {
  background-color: #5bc0de;
}

.Toast__Content {
  word-wrap: break-word;
  word-break: break-all;
  margin: 1rem;
}

@keyframes slide-down {
  0% {
    transform: translateY(-100%);
  }
  15% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(0);
  }
  85% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}

@keyframes slide-down-o {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
  95% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}
