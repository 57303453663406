.DataCard {
  margin: 1rem;
  padding: 0.8rem;
  border-radius: 5px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.541);
  min-height: 6rem;
}
.DataCard__Titulo {
  font-size: 1.2rem;
  font-weight: 600;
  font-family: Oswald,Source Sans Pro,sans-serif;
  letter-spacing: 1px;
}
.DataCard__Fecha {
  font-size: 0.8rem;
}
.DataCard__Descripcion {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.DataCard__Footer {
  padding-left: 0.5rem;
}
.DataCard__Footer a {
  color: #013467;
  padding: 0.2rem;
  font-weight: 600;
}

.DataCard.Encuesta {
  background-color: #013467;
  color: #FFF;
  min-height: unset;
}
.DataCard.Encuesta .DataCard__Footer {
  display: none;
}