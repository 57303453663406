.Notificacion__Button {
  border: 1px solid white;
  border-radius: 100%;
  width: 40;
  height: 40;
}
.Sin-Leer::after {
  content: '';
  border: 6.8px solid #d8322d;
  border-radius: 100%;
  position: absolute;
  top: 8px;
  right: 4px;
}