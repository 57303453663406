.loader-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loader {
  width: 5rem;
  min-height: 5rem;
  border-right: 1rem solid #07c;
  border-left: 1rem solid #121f68;
  border-bottom: 1rem solid rgb(255, 255, 255);
  border-top: 1rem solid rgb(255, 255, 255);
  border-radius: 100%;
  /* add animation spin for infinite time and slow pace */
  animation: spin 2s linear infinite;
}

.loader-text {
  text-align: center;
  font-size: 1rem;
  padding-top: 1rem;
}

@keyframes spin {
  /* keyframe to make an element rotate infinite */
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}