.bg-overlay.active {
  /* an overlay for the background of the form */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 35;
}

.bg-overlay.inactive {
  /* an overlay for the background of the form */
  display: none;
}

.event-form {
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1rem;
  position: relative;
  width: 100%;
  max-width: calc(100vw - 4rem);
  min-height: calc(100vh - 30rem);
}

.form-bar {
  display: flex;
  align-items: center;
  justify-content: right;
  padding: 0;
}
.form-bar button {
  font-size: 1.5rem;
  border-radius: 100%;
  background-color: #d15757;
  color: #fff;
  border: none;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2px;
}

.form-title, .form-title h4 {
  padding: 0;
  margin: 0;
}
.form-title h4 {
  font-size: 1.2rem;
  font-weight: 500;
  padding-bottom: 0.5rem;
}

.form-body {
  padding: 1rem 0;
}

.form-body .form-field {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 1rem;
  margin-top: 0.5rem;
}

.form-field input {
  margin-left: 0.5rem;
  padding: 0.5rem 1rem;
  width: calc(100% - 4rem);
}

.form-submit {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1rem;
  font-size: 1.2rem;
  font-weight: 500;
}

.form-submit button {
  margin-left: 0.5rem;
  padding: 0.5rem 1rem;
}
