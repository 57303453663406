.Confirm.active {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #31313185;
  z-index: 35;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Confirm.inactive {
  display: none;
}

.Confirm__Content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-width: 300px;
  max-width: 100%;
  min-height: calc(50vh - 8rem);
  background-color: #EEE;
  color: #333;
  font-size: 1rem;
  border-radius: 5px;
  margin: 1.5rem;
}


.Confirm__Header {
  padding: 1.5rem 0;
  border-radius: 5px;
  width: 100%;
  text-indent: 1rem;
  color: #FFF;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 30%;
  text-align: left;
  text-transform: uppercase;
  background-color: #013467;
}

.Confirm__Body {
  width: 100%;
  height: 50%;
  text-align: left;
}
.Body__Message {
  padding: 1rem;
  font-size: 1rem;
  word-wrap: break-word;
  line-height: 125%;
}

.Confirm__Buttons {
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Confirm__Buttons button {
  margin: 1rem 0.5rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border: 0;
  border-radius: 5px;
  background-color: #58738b;
  color: #fff;
}
.Confirm__Buttons button.Button.Cancel {
  background-color: #d8322d;
}
.Confirm__Buttons button.Button.Ok {
  background-color: #25aa25;
}